import { LockSimple, Spinner } from "@phosphor-icons/react";
import { useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";

import { usersQueryKeys, useUsers } from "@/hooks";
import i18n from "@/i18n";
import type { ModalProps } from "@/shared.types";
import type { PlanName, User } from "@/types";
import {
  Button,
  CheckboxGroup,
  Circle,
  errorToast,
  Heading,
  Modal,
  RadioButtonGroup,
} from "@/ui";
import {
  mapPlansToAvailablePlanNames,
  transformPlanNamesToApiFormat,
} from "@/utils/planUtils";

interface LocationState {
  user: User;
}

export const ManageAccessLevelModal = ({ show, onClose }: ModalProps) => {
  const location = useLocation();
  const state = location.state as LocationState;

  if (!state?.user) {
    errorToast(i18n.t("error.general"));
  }

  const user = state.user;
  const previouslySelectedPlans = mapPlansToAvailablePlanNames(user.plans);

  const { control, watch } = useForm<{
    radioOption: PlanName[];
    checkboxOption: PlanName[];
  }>({
    defaultValues: {
      radioOption: ["self_paced", "courses", "trainings", "practices_yearly"],
      checkboxOption: previouslySelectedPlans,
    },
  });
  const selectedRadioValue = watch("radioOption");
  const selectedCheckboxValue = watch("checkboxOption");

  const radioOptions = [
    {
      label: i18n.t("users.full_access"),
      value: ["self_paced", "courses", "trainings", "practices_yearly"],
    },
    { label: i18n.t("users.choose_specific_subscriptions"), value: [""] },
  ];

  const checkboxOptions = [
    {
      label: (
        <span className="font-bold">{i18n.t("users.self-paced_course")}</span>
      ),
      value: "self_paced",
      disabled: false,
    },
    {
      label: (
        <div>
          <span className="font-bold">{i18n.t("users.course_materials")}</span>
          <p className="text-sm text-neutral-700">
            {i18n.t("users.enabling_this_option_does_not_provide_access")}
          </p>
          <p className="text-sm text-neutral-700">
            {i18n.t("users.access_to_materials_is_done_on_section")}{" "}
            <span className="text-sm font-semibold text-neutral-700">
              {i18n.t("users.courses_and_materials_section")}
            </span>
          </p>
        </div>
      ),
      value: "courses",
      disabled: false,
    },
    {
      label: (
        <div>
          <span className="font-bold">{i18n.t("users.teacher_materials")}</span>
          <p className="text-sm text-neutral-700">
            {i18n.t("users.enabling_this_option_does_not_provide_access")}
          </p>
          <p className="text-sm text-neutral-700">
            {i18n.t("users.access_to_materials_is_done_on_section")}{" "}
            <span className="text-sm font-semibold text-neutral-700">
              {i18n.t("users.courses_and_materials_section")}
            </span>
          </p>
        </div>
      ),
      value: "trainings",
      disabled: false,
    },
    {
      label: (
        <div>
          <span className="font-bold">
            {i18n.t("users.mindfulness_practices")}
          </span>
          <p className="text-sm text-neutral-700">
            {t("users.users_wont_be_able_to_view_the_course_practices")}
          </p>
        </div>
      ),
      value: "practices_yearly",
      disabled: false,
    },
  ];

  const filteredCheckboxOptions = checkboxOptions.map((option) => {
    const isBatchUser = user.batch_user;
    const wasPlanBought =
      user.plans.find((plan) => plan.name === option.value)?.free_access ===
      false;
    const wasPlanPreviouslySelected = previouslySelectedPlans.includes(
      option.value as PlanName,
    );

    if ((isBatchUser || wasPlanBought) && wasPlanPreviouslySelected) {
      return {
        ...option,
        disabled: true,
      };
    }
    return option;
  });

  const queryClient = useQueryClient();
  const { useUpdateUserAccessLevels } = useUsers();
  const { mutate: updateUserAccessLevels, isPending } =
    useUpdateUserAccessLevels();

  const handleManageButton = () => {
    const userId = user.id;
    const accessLevels = transformPlanNamesToApiFormat(
      selectedRadioValue[0] === "" ? selectedCheckboxValue : selectedRadioValue,
    );

    updateUserAccessLevels(
      { userId, accessLevels },
      {
        onSuccess: () => {
          void queryClient.invalidateQueries({
            queryKey: usersQueryKeys.useUsers(),
            exact: false,
          });
          onClose();
        },
        onError: (err) => {
          errorToast(err);
        },
      },
    );
  };

  return (
    <Modal
      show={show}
      title={
        <div className="flex w-[492px] flex-col gap-y-7">
          <div className="flex flex-col gap-y-2">
            <Heading
              containerClassName="items-center"
              heading={t("users.manage_access_levels")}
              left={
                <Circle>
                  <LockSimple size={24} className="text-tertiary-800" />
                </Circle>
              }
            />
            <p className="text-base text-neutral-700">
              {`${t("users.access_for")} ${user.email}`}
            </p>
            <p className="rounded-lg bg-warning-200 p-4 text-base text-warning-800">
              {t("users.access_settings_cannot_be_modified")}
            </p>
          </div>
        </div>
      }
      className="py-10"
      onClose={onClose}
    >
      <div className="flex flex-col gap-y-10">
        <div className="flex flex-col gap-y-8">
          <RadioButtonGroup
            name="radioOption"
            control={control}
            items={radioOptions}
            label={
              <span className="text-sm text-tertiary-600">
                {t("users.choose_a_type_of_access")}
              </span>
            }
            containerClassName="bg-tertiary-100 gap-x-3 border-none"
            className="px-3 py-2.5"
          />

          {selectedRadioValue[0] === "" && (
            <CheckboxGroup
              name="checkboxOption"
              control={control}
              items={filteredCheckboxOptions}
              label={
                <span className="text-sm text-tertiary-600">
                  {t("users.select_one_or_multiple_subscription_types")}
                </span>
              }
              containerClassName="bg-tertiary-100 border-none"
            />
          )}
        </div>

        <div className="flex flex-row justify-center gap-x-2">
          <Button
            onClick={() => onClose()}
            className="flex-grow"
            variant="outlined"
          >
            {t("general.cancel")}
          </Button>
          <Button
            onClick={() => handleManageButton()}
            className="flex-grow"
            variant="filled"
          >
            {isPending ? (
              <Spinner className="h-5 w-5" />
            ) : (
              t("general.save_changes")
            )}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
