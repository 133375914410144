import type { ForwardedRef, ReactNode } from "react";
import type { Control, FieldValues, Path } from "react-hook-form";
import { useController } from "react-hook-form";

import { forwardRef, tw } from "@/utils";
import { Checkbox } from "./CheckBox";
import { Label } from "./Label";
import { Message } from "./Message";

interface CheckboxGroupProps<T extends FieldValues> {
  name: Path<T>;
  control: Control<T>;
  items: { label: ReactNode; value: string | number; disabled?: boolean }[];
  label?: ReactNode;
  error?: string;
  containerClassName?: string;
  className?: string;
  compact?: boolean;
  message?: string;
  required?: boolean;
}

export const CheckboxGroup = forwardRef(
  <T extends FieldValues>(
    {
      name,
      control,
      items,
      label,
      error,
      containerClassName,
      className,
      compact,
      message,
      required = false,
    }: CheckboxGroupProps<T>,
    _: ForwardedRef<HTMLInputElement>,
  ) => {
    const {
      field: { onChange, value, ref: fieldRef },
    } = useController({
      name,
      control,
    });

    const valueTyped: (string | number)[] = Array.isArray(value) ? value : [];

    const handleChange = (itemValue: string | number) => {
      if (valueTyped.includes(itemValue)) {
        onChange(valueTyped.filter((val) => val !== itemValue));
      } else {
        onChange([...valueTyped, itemValue]);
      }
    };

    return (
      <div
        className={tw(
          "flex flex-col gap-y-2 rounded-2xl border border-neutral-400 bg-neutral-100 p-4",
          containerClassName,
        )}
      >
        {!!label && (
          <div className="flex flex-row gap-x-1">
            <Label
              htmlFor={name}
              label={label}
              className="text-lg font-semibold text-neutral-900"
            />
            {required && (
              <span className="text-lg font-semibold text-error-500">*</span>
            )}
          </div>
        )}
        <div className={tw("flex flex-col", className)}>
          {items.map((item) => (
            <Checkbox
              key={item.value.toString()}
              id={`${name}-${item.value.toString()}`}
              label={item.label}
              value={item.value}
              checked={valueTyped.includes(item.value)}
              onChange={() => handleChange(item.value)}
              error={error}
              ref={fieldRef}
              disabled={item.disabled}
            />
          ))}
        </div>
        {(!!compact || !!message || !!error) && (
          <Message message={message} error={error} />
        )}
      </div>
    );
  },
);
