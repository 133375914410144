import type { DisplayColor } from "../commonTypes";
import type { Plan } from "./plan";

export type Role = "partner" | "admin" | "loggedOut";

export type AccessType =
  | "Full free access"
  | "Partial free access"
  | "No free access";

export interface User {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  country: string;
  role: Role;
  memberships: string[];
  user_type: Role;
  access_type: AccessType;
  locked: boolean;
  batch_user: boolean;
  terms_and_conditions_accepted_at: Date;
  plans: Plan[];
}

export interface ProfileInformation {
  first_name: string;
  last_name: string;
  email: string;
  country: string;
}

export function mapAccessTypeToColor(accessType: AccessType): DisplayColor {
  switch (accessType) {
    case "Full free access":
      return "success";
    case "Partial free access":
      return "warning";
    case "No free access":
      return "error";
  }
}
